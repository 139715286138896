.App {
  text-align: center;
  margin: 20px;
}

.Provider-Services {
  margin: 30px;
  display: block;
  border: 1px gray solid;
}

.Provider-Header {
  font-size: large;
  font-weight: bold;

  display: block;
  border-bottom: 1px gray solid;
  background-color:lavender;
}

ul.Provider-Technologies li {
  display: inline-block;
  margin-right: 20px;
}